import { UrlMatchResult, UrlSegment } from '@angular/router';


export function mediaUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
    const [root, slug, productionDate, mediaType, title] = url;
    if (
        root?.path === 'shows'
        && slug?.path
        && productionDate?.path.match(/^\d\d\d\d-\d\d-\d\d(-\d+)?$/)
        && mediaType?.path.match(/^(?:watch|clips|podcasts|episodes)$/)
        //&& title?.path
    ) {
        return {
            consumed: [],
            posParams: {
                newUrlStructures: new UrlSegment('true', {})
            }
        };
    }

    return null;
}
