import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Redirection } from '@tytapp/common';
import { UserService } from '@tytapp/user';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
    private userService = inject(UserService);
    private redirection = inject(Redirection);
    ngOnInit() {
        setTimeout(() => {
            this.userService.completeLogout();
            this.userService.user = null;
            this.redirection.go('/');
        });
    }
}
