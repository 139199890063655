<button mat-icon-button aria-label="Close" class="close" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<div class="c2a">
  <p>Open in the TYT app</p>

  <div class="store-links">
      <a class="ios" target="_blank" [href]="appStoreUrl">
          <img class="apple" src="/assets/appstore/apple/black.svg" />
      </a>
      <a class="android" target="_blank" [href]="playStoreUrl">
          <img src="/assets/appstore/google/black.png" />
      </a>
  </div>
</div>

<div class="compatible-platform">
  <p>Already have the app?</p>

  <div class="open-options">
    <button mat-raised-button color="primary" (click)="openInApp()">Open in app</button>
  </div>
</div>