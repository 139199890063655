import { Component, OnInit, Input, inject } from '@angular/core';
import { ApiProduction, ApiVOD } from '@tytapp/api';
import { ProductionsService } from '@tytapp/common-ui';

@Component({
    selector: 'tyt-feed-item',
    templateUrl: './feed-item.component.html',
    styleUrls: ['./feed-item.component.scss']
})
export class FeedItemComponent implements OnInit {
    productionsService = inject(ProductionsService);


    @Input()
    public production: ApiProduction;

    @Input()
    public mode: "clips" | "episodes";

    @Input()
    audienceType: string;

    @Input()
    public limit = 1;

    @Input()
    sessions = {};


    seeMoreClips: boolean = false;

    ngOnInit() {
    }

    modeForItem(item: ApiVOD) {
        return this.mode;
    }

    seeMore: boolean = false;

    get allItems() {
        let items = [];
        if (this.mode == 'clips') {
            items = <any>this.production.vod_clips;
        } else {
            items = <any>this.production.full_length_vods;
        }

        return items;
    }

    get items() {
        let items = this.allItems;

        if (this.mode !== 'clips') {
            items = items.slice(0, 1);
        } else {
            if (!this.seeMore) {
                items = items.slice(0, 1);
            }
        }

        return items;
    }

    watchUrl(vod: ApiVOD) {
        return this.productionsService.getWatchUrlSync(this.production, this.modeForItem(vod), vod);
    }
}
