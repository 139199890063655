@if (items[0]) {
  <div class="feed-item-container">
    <div class="feed-item">
      <div class="production-thumbnail">
        <a
          [routerLink]="watchUrl(items[0])">
          <!-- <img src="{{production.thumbnail_landscape1}}" alt="production thumbnail"/> -->
          <div class="production-thumbnail-landscape" [responsiveBackgroundImage]="production.thumbnail_landscape1"></div>
        </a>
      </div>
      <div [ngClass]="mode === 'clips' ? 'clips item': 'item'">
        <a routerLink="/shows/{{production.show.slug || production.show.id}}">
          <div role="img" [responsiveBackgroundImage]="production.show.logo_square" alt="show logo" class="show-logo"></div>
        </a>
        <div class="item-content-container">
          <div class="production-date">{{production.date_simple | date : 'mediumDate'}}</div>
          <h1><a routerLink="/shows/{{production.show.slug || production.show.id}}">{{production.show.name}}</a></h1>
          @for (item of items; track item) {
            <summary>
              <a
                [routerLink]="watchUrl(item)">
                {{item.description}}
              </a>
            </summary>
            <tyt-playback-status-bar [item]="item" (sessionChanged)="sessions[item.id] = $event">
            </tyt-playback-status-bar>
            <div class="addendum">
              <div class="tyt-tags">
                <tyt-content-badge
                  [type]="mode"
                  [premium]="item.is_premium"
                  [unlocked]="item.unlock_premium"
                ></tyt-content-badge>
              </div>
              <ul class="facts">
                <li>{{item.duration | duration}}</li>
                <li>
                  <tyt-comments-count identifier="vod_{{item.id}}"></tyt-comments-count>
                </li>
              </ul>
            </div>
          }
          @if (mode === 'clips' && allItems.length > 1) {
            <br />
            <a class="see-more" (click)="seeMore = !seeMore" href="javascript:;">
              @if (seeMore) {
                <picture responsive class="arrow" alt="arrow up" [widthFactor]="0.1"
                src="/assets/arrow_up.png"></picture>
              }
              <span>See {{seeMore? 'less': 'more'}} from this episode</span>
              @if (!seeMore) {
                <picture responsive class="arrow" alt="arrow down" [widthFactor]="0.1"
                src="/assets/arrow_down.png"></picture>
              }
            </a>
          }
        </div>
      </div>
    </div>
  </div>
}
