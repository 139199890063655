import { Component, inject, OnInit } from '@angular/core';
import { PageComponent, Transfer, PagedArray } from '@tytapp/common';
import { ActivatedRoute } from '@angular/router';
import { ProductionsService } from '@tytapp/common-ui';
import { combineLatest } from 'rxjs';
import { UserService } from '@tytapp/user';
import { ApiProduction } from '@tytapp/api';

@Component({
    selector: 'tyt-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss']
})
export class FeedComponent extends PageComponent implements OnInit {
    private productionsService = inject(ProductionsService);
    private userService = inject(UserService);

    @Transfer()
    content: string;

    @Transfer()
    clips: PagedArray<ApiProduction>;
    @Transfer()
    episodes: PagedArray<ApiProduction>;
    @Transfer()
    candidates: PagedArray<ApiProduction>;

    // Paging
    @Transfer()
    total: number;
    @Transfer()
    page: number;
    @Transfer()
    size: number;

    audienceType: string = 'visitor';
    ready: boolean = false;

    async init() {
        this.size = 10;

        let observer = combineLatest(this.route.params, this.route.queryParams, (params, queries) => ({ params, queries }));
        observer.subscribe(this.update.bind(this));

        this.audienceType = this.userService.audienceType;
    }

    async update({ params, queries }) {
        if (this.hasServerState)
            return;

        if (queries.page) {
            this.page = queries.page;
        } else {
            this.page = 1;
        }

        this.ready = false;
        this.clips = null;
        this.episodes = null;
        this.candidates = null;

        if (params.content === 'candidates') {
            this.content = '2020 presidential candidates';
            this.candidates = <any>await this.productionsService.latestCandidates((this.page - 1) * this.size, this.size);
            this.total = this.candidates.total;
        } else if (params.content === 'episodes') {
            this.content = 'episodes';
            this.episodes = <any>await this.productionsService.latestVODs((this.page - 1) * this.size, this.size);
            this.total = this.episodes.total;
        } else {
            this.content = 'clips';
            this.clips = <any>await this.productionsService.latestClips((this.page - 1) * this.size, this.size);
            this.total = this.clips.total;
        }

        this.pageTitle = `Latest ${this.content}`;
        this.ready = true;

    }


    goToPage(page: number) {
        this.router.navigate([], { queryParams: { page } });
    }


}
