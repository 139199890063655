<div>
  <div class="mini-nav">
    <h1 class="title">Latest {{content}}</h1>
    <div class="spacer"></div>

    @if (['episodes', 'clips'].includes(content)) {
      <div class="menu">
        <a routerLink="/feed/episodes" routerLinkActive="active">
          <img src="/assets/icons-2019/shows.svg" aria-hidden="true" />
          Episodes
        </a>
        <a routerLink="/feed/clips" routerLinkActive="active">
          <img src="/assets/icons-2019/shows.svg" aria-hidden="true" />
          Clips
        </a>
        <a routerLink="/topics" routerLinkActive="active">
          <img src="/assets/icons-2019/topics.svg" aria-hidden="true" />
          Topics
        </a>
      </div>
    }

  </div>
  @if (!ready) {
    <app-spinner></app-spinner>
  } @else {
    <div class="content">
      @if (clips) {
        @for (clip of clips; track clip) {
          <tyt-feed-item [audienceType]="audienceType" [production]="clip" mode="clips"></tyt-feed-item>
        }
      }
      @if (episodes) {
        @for (episode of episodes; track episode) {
          <tyt-feed-item [audienceType]="audienceType" [production]="episode" mode="episodes"></tyt-feed-item>
        }
      }
      @if (candidates) {
        @for (candidate of candidates; track candidate) {
          <tyt-feed-item [audienceType]="audienceType" [production]="candidate" mode="episodes"></tyt-feed-item>
        }
      }
    </div>
  }
  <tyt-pager [total]="total" [page]="page" [size]="size" (changePage)="goToPage($event)"></tyt-pager>
</div>
