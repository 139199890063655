import { Component, inject } from '@angular/core';
import { Redirection } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
    templateUrl: './open-in-mobile-app.component.html',
    styleUrls: ['./open-in-mobile-app.component.scss']
})
export class OpenInMobileAppComponent {
    private redirection = inject(Redirection);
    private data = inject(MAT_BOTTOM_SHEET_DATA);
    private sheetRef = inject(MatBottomSheetRef);

    get appStoreUrl() {
        return environment.urls.appStoreUrl;
    }

    get playStoreUrl() {
        return environment.urls.playStoreUrl;
    }

    get url(): string {
        return this.data.url;
    }

    openInApp() {
        window.open(`tyt://mobile.tyt.com${this.url}`, '_blank');
        this.redirection.go(this.url);
    }

    close() {
        this.sheetRef.dismiss();
    }

    openOnWeb() {
        this.redirection.go(this.url, false);
    }
}