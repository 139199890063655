<div class="overlay">
  <div class="container">
    @if (loading) {
      <div class="loading">
        <h1>{{loadingMessage}}</h1>
        <app-spinner></app-spinner>
      </div>
    } @else {
      @if (step === 'email') {
        <h1>Welcome to TYT.com!</h1>
        Looks like we still need your email address. Please enter it below to
        ensure you always have access to your TYT account.
        @if (errorMessage) {
          <br />
          <br />
          <div class="alert alert-danger">
            {{errorMessage}}
          </div>
        }
        <br /><br />
        <form (submit)="saveEmail()">
          <div class="input-container">
            <input type="text" name="email" [(ngModel)]="emailAddress" placeholder="Email Address" /><br />
          </div>
          <div style="text-align: right;">
            <button mat-raised-button color="primary">Continue</button>
          </div>
        </form>
      }
      @if (step === 'email-opt-in') {
        <h1>Stay up to date with TYT</h1>
        <form class="email-optin" (submit)="saveOptin()">
          <label>
            <input type="checkbox" name="emailOptin" [(ngModel)]="emailOptin" />
            <div>
              Receive news and alerts and daily recaps from TYT to your
              email address <strong>{{user.email}}</strong>. You may manage
              communication preferences and opt-out at any time.
            </div>
          </label>
          <br />
          <div style="text-align: right;">
            <button mat-raised-button color="primary">Continue</button>
          </div>
        </form>
      }
      @if (step === 'ytcm') {
        @if (ytcmMissing) {
          <h1>Connect your YouTube membership?</h1>
          <p>If you purchased TYT membership on YouTube you can
            enjoy all the benefits of membership on TYT.com.
            If you have not purchased membership
            via YouTube, you can <a routerLink="/">skip this step</a>.
          </p>
          You have connected the following YouTube accounts to your TYT account:
          <div class="connected-accounts">
            @for (account of youtubeConnections; track account) {
              <div class="connected-account" [ngClass]="account.provider">
                <img src="/assets/icons/{{account.provider}}.svg" [alt]="account.provider" class="icon">
                <div>
                  <div class="account-name">{{(nicknameForConnectedAccount(account))}}</div>
                  <ul class="facts">
                    <li>YouTube</li>
                    <li>{{account.email}}</li>
                    <!--
                    <li>
                      <a href="javascript:;">Remove</a>
                    </li>
                    -->
                  </ul>
                </div>
              </div>
            }
          </div>
          <div style="text-align: right;">
            <a href="javascript:;" (click)="connectWithProvider('youtube')" class="with-inline-icon">
              <img src="/assets/icons/youtube.svg" alt="youtube">
              Connect another account
            </a>
          </div>
          <br />
          We did not find a YouTube channel membership on any of the accounts you've
          connected. You can also set up your channel membership at any time on your Accounts page.
          <div style="text-align: right;">
            <a routerLink="/" mat-raised-button color="primary">Skip</a>
          </div>
          <div class="hint">
            <strong>Having trouble?</strong><br />
            <p>Make sure to connect the YouTube identity that you used to
              purchase membership. If you have multiple brand accounts or Google
              accounts, you'll need to connect the right one in order for us to
              connect the dots.
            </p>
          </div>
        } @else {
          <h1>Hello YouTube Channel Member!</h1>
          <p>Looks like you have purchased TYT membership via YouTube!
          Thanks for supporting TYT!</p>
          <p>
            Your membership can be used here on TYT.com, giving you
            access to all the perks of TYT membership, including:
          </p>
          <ul>
            <li>Watch all of our members-only content including
            live streams</li>
            <li>Download content for viewing offline</li>
            <li>Subscribe to members-only versions of our podcasts using
            your favorite podcast player</li>
            <li>Participate in the TYT community</li>
          </ul>
          <a mat-raised-button color="primary" routerLink="/">Get Started</a>
          <a routerLink="/settings/profile">Profile</a> |
          <a routerLink="/settings/account">Account</a>
        }
      }
    }
  </div>
</div>
