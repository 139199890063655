import { ApplicationRef, Compiler, InjectFlags, Injector, NgModuleRef, PlatformRef, Type, inject } from '@angular/core';
import { Route, Routes, ROUTES as NG_ROUTES } from '@angular/router';
import { Injectable } from '@banta/common';
import { ROUTES } from '@tytapp/app/routes';
import { SlugComponent } from '@tytapp/slugs';

export interface MatchedRoute extends Route {
    url: string;
    moduleRef?: NgModuleRef<any>;
    parent?: MatchedRoute;
    params?: Record<string, string>;
}

@Injectable()
export class RouteMatcher {
    private platformRef = inject(PlatformRef);
    private compiler = inject(Compiler);
    private injector = inject(Injector);

    getPrefixOfMatch(route: MatchedRoute) {
        if (!route)
            return '';

        return `${route.parent ? `${this.getPrefixOfMatch(route.parent)}/` : ''}${route.path ?? ''}`;
    }

    isExplicit(route: MatchedRoute) {
        if (!route)
            return false;

        if (route.path === '**')
            return false;

        // NOTE: Ignoring the empty URL here prevents the home page from not being considered explicit, since
        // the SlugComponent handles the root URL in order to determine whether to send the user to the TYTN home
        // experience or the traditional TYT.com home experience.

        if (route.component === SlugComponent && route.url !== '')
            return false;

        return true;
    }

    /**
     * Check if a route with this URL exists and is not an implicit route (like the 404 page or the dynamic Slug handler)
     * @param url
     * @returns
     */
    async exists(url: string): Promise<boolean> {
        return this.isExplicit(await this.match(url));
    }

    async match(url: string, includeRefs: boolean = true, routes: Routes = ROUTES, parent?: MatchedRoute): Promise<MatchedRoute> {
        url = url.replace(/^\/+/, '');
        let parentPrefix = this.getPrefixOfMatch(parent);

        for (let route of routes) {
            let routeUrl = `${parentPrefix ? `${parentPrefix}/` : ``}${route.path}`.replace(/^\/|\/$/g, '');
            let paramNames = Array.from(routeUrl.matchAll(/:[a-z]+/g)).flat().map(x => x.slice(1));
            let routeRegex = new RegExp(`^${routeUrl.replace(/\*\*/, '.*').replace(/:[a-z]+/g, '([^/]+)')}$`);
            let routeSubMatch = new RegExp(`^${routeUrl.replace(/\*\*/, '.*').replace(/:[a-z]+/g, '([^/]+)')}(?:$|/)`);

            let exactMatch = routeRegex.test(url);
            let match = url.match(routeSubMatch);
            let subMatch = !!match;

            if (route.loadChildren && (exactMatch || subMatch)) {

                let moduleClass = <Type<any>> await route.loadChildren();
                let modFactory = await this.compiler.compileModuleAsync(moduleClass);
                let moduleRef = modFactory.create(this.injector);
                let childRoutes = moduleRef.injector.get(NG_ROUTES, [], InjectFlags.Self).flat();
                return this.match(url, includeRefs, childRoutes, {
                    ...route,
                    url: routeUrl,
                    parent,
                    params: Object.fromEntries(Array.from(match).slice(1).map((v, i) => [ paramNames[i] ?? `${i}`, v ])),
                    moduleRef: includeRefs ? moduleRef : undefined
                });
            }

            if (exactMatch) {
                return {
                    ...route,
                    url: routeUrl,
                    parent,
                    params: Object.fromEntries(Array.from(match).slice(1).map((v, i) => [ paramNames[i], v ]))
                };
            }
        }
        return undefined;
    }
}