import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BillingService } from '@tytapp/billing';
import { Redirection } from '@tytapp/common';
import { isClientSide } from '@tytapp/environment-utils';

export default (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const redirection = inject(Redirection);
    const router = inject(Router);
    const billing = inject(BillingService);

    return (async () => {
        // Because this is a navigation guard, and AppComponent will only be loaded once this navigation
        // guard says its good to go, we cannot simply wait for appstatus to be ready, since this will block
        // appstatus API call from happening. To avoid this we are using a "holding page" with a spinner, and
        // we elect to perform the redirection checks _out of flow_

        const isFirstNavigation = !router.navigated;

        setTimeout(async () => {
            await billing.presentMembershipOffers();
            if (isClientSide() && isFirstNavigation)
                redirection.go(`/`, true);
        })

        return false;
    })();
}