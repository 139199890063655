import { Component, inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { OpenInMobileAppComponent } from '@tytapp/app/open-in-mobile-app/open-in-mobile-app.component';
import { Redirection } from '@tytapp/common';
import { environment } from '@tytapp/environment';

interface RelatedApp {
    id: string;
    platform: 'play' | 'itunes' | 'webapp';
    url: string;
}

@Component({
    template: ''
})
export class OpenInMobileAppLinkHandlerComponent {
    private bottomSheet = inject(MatBottomSheet);
    private redirection = inject(Redirection);
    private router = inject(Router);

    private async getInstalledApps(): Promise<RelatedApp[]> {
        if ('getInstalledRelatedApps' in navigator)
            return await (navigator as any).getInstalledRelatedApps();
        return [];
    }

    async ngOnInit() {
        let url = '/' + this.router.url.replace(/^\/m\//, '');
        let installedApps = await this.getInstalledApps();

        if (!environment.isNativeBuild) {
            if (installedApps.some(x => ['itunes', 'play'].includes(x.platform))) {
                window.open(`tyt.open://mobile.tyt.com${url}`, '_blank');
            } else {
                this.bottomSheet.open(OpenInMobileAppComponent, {
                    data: { url }
                });
            }
        }

        this.redirection.go(url, true);
    }
}