import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Redirection } from '@tytapp/common';

@Component({
    template: ''
})
export class RerouteComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private redirection: Redirection
    ) {}

    async ngOnInit() {
        this.redirection.go(this.route.snapshot.data.url, true);
    }

}

